import axios from 'axios'

export function getAdministradores () {
  return axios.get('/auth/administrators')
  .then(response => {
      return response.data
  })
}

export function addAdministrador (admin) {
  return axios.post('/auth/new-administrator', admin)
  .then(response => {
    return response.data
  })
}

export function updateAdministrador (id, admin) {
  return axios.put('/auth/update-administrator/' + id, admin)
  .then(response => {
    return response.data
  })
}

export function bajaAdministrador (id, admin) {
  return axios.put('/auth/baja-administrator/' + id, admin)
  .then(response => {
    return response.data
  })
}

export default {
  getAdministradores,
  addAdministrador,
  updateAdministrador,
  bajaAdministrador,
}
