<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-progress-linear
      v-show="progressBar"
      indeterminate
      color="orange"
    />
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="colorSnak"
    >
      {{ text }}
      <v-btn
        color="black"
        text
        @click="snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
    <base-material-card
      color="primary"
      icon="mdi-account-tie"
      title="Listado de administradores"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="administradores"
        sort-by="name"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            />
            <v-spacer />
            <v-dialog
              v-model="dialog"
              max-width="1000px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Nuevo
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-form
                    ref="form"
                    v-model="valid"
                    :lazy-validation="lazy"
                  >
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="5"
                        >
                          <v-text-field
                            v-model="editedItem.name"
                            :rules="[v => !!v || 'Campo requerido']"
                            label="Nombre y apellidos"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="5"
                        >
                          <v-text-field
                            v-model="editedItem.email"
                            :rules="emailRules"
                            hint="Se usa para iniciar sesión"
                            persistent-hint
                            label="Email"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="2"
                        >
                          <v-select
                            v-model="editedItem.rol"
                            :rules="[v => !!v || 'El rol es requerido']"
                            item-text="name"
                            item-value="id"
                            :items="items"
                            label="Rol"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                  >
                    Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogDelete"
              max-width="500px"
            >
              <v-card>
                <v-card-title
                  class="headline"
                >
                  Confirmar alta o baja de usuario
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeDelete"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteItemConfirm"
                  >
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="item.baja == 0"
                small
                class="mr-2"
                color="red darken-4"
                v-bind="attrs"
                v-on="on"
                @click="deleteItem(item)"
              >
                mdi-account-lock
              </v-icon>
            </template>
            <span>Dar de baja</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="item.baja == 1"
                small
                class="mr-2"
                color="red darken-4"
                v-bind="attrs"
                v-on="on"
                @click="deleteItem(item)"
              >
                mdi-account-check
              </v-icon>
            </template>
            <span>Dar de alta</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
  import AdministradoresApi from '@/services/api/Administradores'

  export default {
    data: () => ({
      progressBar: false,
      colorSnak: 'info',
      snackbar: false,
      text: '',
      timeout: 3000,
      search: '',
      valid: true,
      lazy: false,
      dialog: false,
      dialogDelete: false,
      items: [
        { name: 'ROLE_SA', id: 1 },
        { name: 'ROLE_ADMIN', id: 2 },
      ],
      headers: [
        {
          text: 'Nombre y apellidos',
          align: 'start',
          value: 'name',
        },
        { text: 'Email', value: 'email' },
        { text: 'Rol', value: 'rol.name' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      administradores: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        email: '',
        rol: 0,
      },
      defaultItem: {
        name: '',
        email: '',
        rol: 0,
      },
      emailRules: [
        v => !!v || 'El correo electrónico es requerido',
        v => /.+@.+\..+/.test(v) || 'Ingresa un correo válido',
      ],
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo usuario' : 'Editar usuario'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      AdministradoresApi.getAdministradores()
        .then((usuarios) => {
          this.administradores = usuarios
        })
        .catch((error) => {
          console.log(error)
          this.text = 'No ha sido posible obtener los datos'
          this.colorSnak = 'error'
          this.snackbar = true
        })
    },

    methods: {
      editItem (item) {
        this.editedIndex = this.administradores.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.administradores.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.closeDelete()
        this.progressBar = true
        if (this.editedItem.baja === 0) {
          this.editedItem.baja = 1
        } else {
          this.editedItem.baja = 0
        }
        AdministradoresApi.bajaAdministrador(this.editedItem.id, this.editedItem)
          .then(response => {
            this.progressBar = false
            this.text = 'Registro modificado correctamente'
            this.snackbar = true
            this.colorSnak = 'success'
            this.editedIndex = -1
            setTimeout(() => { location.reload() }, 2000)
          })
          .catch(error => {
            console.log(error)
            this.progressBar = false
            this.text = 'El registro no puede ser modificado en este momento'
            this.colorSnak = 'error'
            this.snackbar = true
            this.editedIndex = -1
          })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.$refs.form.validate()) {
          this.progressBar = true
          if (this.editedIndex > -1) {
            const updAdmin = this.editedItem
            const indexItem = this.editedIndex
            AdministradoresApi.updateAdministrador(this.editedItem.id, this.editedItem)
              .then(response => {
                if (updAdmin.rol === 1) {
                  updAdmin.rol = this.items[0]
                }
                if (updAdmin.rol === 2) {
                  updAdmin.rol = this.items[1]
                }
                Object.assign(this.administradores[indexItem], updAdmin)
                this.progressBar = false
                this.text = 'Registro modificado correctamente'
                this.snackbar = true
                this.colorSnak = 'success'
                this.editedIndex = -1
              })
              .catch(error => {
                console.log(error)
                this.progressBar = false
                this.text = 'El registro no puede ser modificado en este momento'
                this.colorSnak = 'error'
                this.snackbar = true
                this.editedIndex = -1
              })
          } else {
            const addAdmin = this.editedItem
            AdministradoresApi.addAdministrador(this.editedItem)
              .then(response => {
                addAdmin.id = response.id
                if (addAdmin.rol === 1) {
                  addAdmin.rol = this.items[0]
                }
                if (addAdmin.rol === 2) {
                  addAdmin.rol = this.items[1]
                }
                this.administradores.push(addAdmin)
                this.progressBar = false
                this.text = 'Usuario creado. Se le ha enviado mail'
                this.colorSnak = 'success'
                this.snackbar = true
                this.editedIndex = -1
              })
              .catch(error => {
                console.log(error)
                this.progressBar = false
                this.text = 'El registro no puede ser creado en este momento'
                this.colorSnak = 'error'
                this.snackbar = true
              })
          }
          this.close()
        }
      },
    },
  }
</script>
